"use client";

import { useRouter } from "next/navigation";
import { useState } from "react";
import { useSelector } from "react-redux";

import ActionButton from "@/components/molecules/action-button/action-button";
// eslint-disable-next-line import/no-named-as-default
import ContentSection from "@/components/molecules/content-section/content-section";
import { LandingPage } from "@/config/app-constant";
import setCookieForHeader from "@/hooks/api/cookie/getCookie";
import { GetQuoteFilter } from "@/hooks/api/questions/getQuote";
import { useAppDispatch } from "@/Redux/hook";
import { RootState } from "@/Redux/root-reducer";
import { getQuoteData } from "@/Redux/Slices/question/question.action";
import { clearQuestion } from "@/Redux/Slices/question/question.slice";
import { LandingSectionProps } from "@/types/LandingPageType";
import { getRouterPathMainButton } from "@/utils/page-routing.utils";

import Style from "./landing-section.module.css";

function LandingSection({
  titleRiskLevel,
  supportingText,
  travelEligibilityContent,
  linkText,
  ctaButtonText,
}: Readonly<LandingSectionProps>) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { quoteResponse } = useSelector((state: RootState) => state.question);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleSubmitButtonClick = async () => {
    setButtonLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    setCookieForHeader().then(async (data: any) => {
      if (data?.code === 409) {
        if (!quoteResponse?.attributes?.tripDetails) {
          const getQuoteFilter: GetQuoteFilter = {
            affiliate: "quoteInit",
            quoteId: null,
          };
          dispatch(getQuoteData(getQuoteFilter));
        }
      } else {
        dispatch(clearQuestion());
      }
      router.push(getRouterPathMainButton(LandingPage));
      setButtonLoading(false);
    });
  };

  return (
    <div
      className={` ${Style.content_box}  md:pt-spacing-xl md:pb-spacing-xxl pt-spacing-xs pb-spacing-m px-spacing-m flex flex-col items-center`}
    >
      <ContentSection
        titleRiskLevel={titleRiskLevel}
        supportingText={supportingText}
        travelEligibilityContent={travelEligibilityContent}
        linkText={linkText}
      />
      <ActionButton
        page={LandingPage}
        disableMain={false}
        isLoading={buttonLoading}
        ctaButtonText={ctaButtonText}
        submitButtonClick={handleSubmitButtonClick}
      />
    </div>
  );
}

export default LandingSection;
