"use client";

import dynamic from "next/dynamic";
import { ReactElement, useEffect, useMemo, useState } from "react";

import { LandingPage } from "@/config/app-constant";
import useGtmPageView from "@/hooks/gtm/useGtmPageView";
import useCustomPageTracking from "@/hooks/page-tracking/use-custom-page-tracking";
import useUpdateProgressBar from "@/hooks/progress/use-update-progress-bar";
import useMediaQuery from "@/hooks/useMediaQuery/useMediaQueryEnhanced";
import { storageAvailable } from "@/utils/common.utils";
import pages from "@/utils/page-routing.utils";

import Styles from "../../app/home-page.module.css";
import RichText from "../atoms/rich-text/rich-text";
import Style from "../organisms/landing-section/landing-section.module.css";

const Footer = dynamic(() => import("@/components/organisms/footer/Footer"), {
  ssr: false,
});

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  helpButton: ReactElement;
  logo: ReactElement;
  pageContent: ReactElement | undefined;
  linkText: string;
}

function AgreementPageWrapper({
  helpButton,
  logo,
  pageContent,
  linkText,
}: Readonly<Props>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [footer, setFooter] = useState<boolean>(false);
  const [isdesktop] = useMediaQuery("(min-width: 600px)");
  const [showOnDesktop, setShowOnDesktop] = useState(0);

  useEffect(() => {
    if (isdesktop) setShowOnDesktop(1);
    if (!isdesktop) setShowOnDesktop(2);
  }, [isdesktop]);

  const percent = useMemo(() => {
    return pages.find((page) => page.name === LandingPage)?.progress ?? -1;
  }, []);

  useUpdateProgressBar(percent);

  const { pageReferrer, pageUrl, pageTitle } =
    useCustomPageTracking(LandingPage);

  useGtmPageView({
    userId: "",
    quoteId: "",
    pageReferrer,
    pageUrl,
    pageTitle,
  });

  useEffect(() => {
    setTimeout(function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (typeof window !== "undefined" && window && (window as any).zE) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).zE.apply(null, ["messenger", "hide"]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).zE.apply(null, [
          "messenger:on",
          "close",
          function f() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).zE.apply(null, ["messenger", "hide"]);
          },
        ]);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      if (storageAvailable() && !sessionStorage.getItem("footer")) {
        setFooter(true);
      }
    }
  }, []);

  return (
    <div
      data-testid="landingPage"
      className={`${Styles.homepageWrapper} bg-surface-neutral-option-2`}
    >
      <div
        className={`${Styles.main_wrapper} max-w-[1200px] mx-auto my-0 w-[100%] relative lg:py-spacing-xxl md:py-spacing-xl 
      py-spacing-l lg:pb-spacing-xxl md:pb-spacing-xl pb-spacing-5xl`}
      >
        <main className=" lg:pb-spacing-xxl md:pb-spacing-xl pb-spacing-l">
          <div
            className={` ${Styles.grid_block} grid md:grid-cols-12 grid-cols-6 gap-spacing-l xl:mx-auto lg:mx-[72px] md:mx-[48px] mx-[24px] relative`}
          >
            <div className={`${Styles.question_layout_wrapper} col-span-12`}>
              <div
                className={` ${Styles.question_layout} bg-dark-surface-accent-1-(main) max-h-full text-surface-neutral-option-2 rounded-radius-xxl min-h-[120px] relative`}
              >
                {helpButton}
                {logo}
                {pageContent}
              </div>
            </div>
          </div>
          {showOnDesktop === 2 && (
            <div
              className={` ${Style.agreement_block} px-spacing-l pt-spacing-l text-left font-proximanova text-base leading-[24px] w-full`}
            >
              <RichText richText={linkText} />
            </div>
          )}
        </main>

        {footer && (
          <footer>
            <Footer />
          </footer>
        )}
      </div>
    </div>
  );
}

export default AgreementPageWrapper;
