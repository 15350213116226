import axioInstance from "@/libs/axios";

// const baseURL = "http://localhost:3000/api";
// eslint-disable-next-line consistent-return
const setCookieForHeader = async () => {
  try {
    const responseCookie = await axioInstance.post(`/createCookie`);
    const cookieData = responseCookie?.data;

    return cookieData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return null;
  }
};

export default setCookieForHeader;
